import { FC, useEffect, useState } from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import "./PaymentReminder.scss";
import CopyRightFooter from "../LayoutComponent/copyrightfooter/CopyRightFooter";
import httpApiCall from "../../service/api.service";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { formatCurrency } from "../../utils/currencyFormatter";
import { formatDateNew } from "../../utils/dateformatter";
import ContentLoader from "react-content-loader";
import { setRecurringData } from "../../Redux/slices/payment.slice";
import PayRecurring from "../PayRecurring/PayRecurring";
import PayNowComponent from "../PayNowComponent/PayNowComponent";
import RecurringPaymentSuccessfull from "../RecurringPaymentSuccessfull/RecurringPaymentSuccessfull";
interface PaymentReminderProps {
  type?: any;
}

type PayRecurringDataResult = {
  recurring_amount?: number;
  date_added?: string;
  patient_payoff_amount?: number;
  treatment_amount?: number;
  service_amount?: number;
  discount_percent?: number;
  pf_status?: number;
  patient_first_name?: string;
  patient_last_name?: string;
  next_payment_date?: string;
  contract_code?: string;
  services?: [];
  payments_paid?: number;
  due_days?: number;
  number_of_payments?: number;
  discount_due_days?: number;
  practice_name?: string;
  doctor_name?:string
  doctor_image?:string
};

const PaymentReminder: FC<PaymentReminderProps> = () => {
  const { token } = useParams<{ token: string }>();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [payNow, setPayNow] = useState({show: false,type: 0});
  const [payRecurringData, setPayRecurringData] = useState<{result?: PayRecurringDataResult;} | null>(null);
  const result: PayRecurringDataResult = payRecurringData?.result ?? {};

  const {
    recurring_amount = 0,
    date_added = "",
    patient_payoff_amount = 0,
    pf_status = 0,
    treatment_amount = 0,
    service_amount = 0,
    patient_first_name = "N/A",
    patient_last_name = "N/A",
    next_payment_date = "",
    discount_percent = 0,
    contract_code = "N/A",
    services = [],
    number_of_payments = 0,
    due_days = 0,
    payments_paid = 0,
    discount_due_days = 0,
    doctor_name = "",
    practice_name = "",
    doctor_image=""
  } = result;


  useEffect(() => {
    const getPayRecurringData = async () => {
      try {
        const resp = await httpApiCall({
          type: "GET",
          api: "proposal/get_pay_recurring_data",
          body: { token },
        });
        setPayRecurringData(resp);
        if (resp.is_error === 1 && resp.flag === 170) {
          navigate("/invalid");
        }
        dispatch(setRecurringData(resp));
      } catch (error) {
        console.error("Error fetching pay recurring data:", error);
      } finally {
      }
    };

    getPayRecurringData();
  }, [token, dispatch]);

  return (
    <>
      {!payNow.show && (
        <Container className="recurring-payment-container">
          {!payRecurringData && (
            <Row>
              <Col>
                <ContentLoader
                  speed={2}
                  width={600}
                  height={500}
                  viewBox="0 0 600 500"
                  backgroundColor="#f3f3f3"
                  foregroundColor="#ecebeb"
                >
                  <rect x="20" y="20" rx="4" ry="4" width="400" height="20" />
                  <rect x="20" y="50" rx="6" ry="6" width="500" height="30" />
                  <rect x="20" y="90" rx="4" ry="4" width="450" height="20" />
                  <rect x="20" y="150" rx="4" ry="4" width="120" height="15" />
                  <rect x="460" y="150" rx="4" ry="4" width="80" height="15" />
                  <rect x="20" y="190" rx="4" ry="4" width="120" height="15" />
                  <rect x="460" y="190" rx="4" ry="4" width="180" height="15" />
                  <rect x="20" y="250" rx="6" ry="6" width="200" height="20" />
                  <rect x="20" y="280" rx="4" ry="4" width="550" height="15" />
                  <rect x="20" y="300" rx="4" ry="4" width="520" height="15" />
                  <rect x="20" y="380" rx="6" ry="6" width="120" height="40" />
                </ContentLoader>
              </Col>
              <Col></Col>
              <Col>
                <ContentLoader
                  speed={2}
                  width={370}
                  height={600} // Adjusted height
                  viewBox="0 0 370 600" // Adjusted viewBox height
                  backgroundColor="#f3f3f3"
                  foregroundColor="#ecebeb"
                  style={{
                    borderRadius: "10px", // Rounded corners
                    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)", // Subtle shadow for a card effect
                  }}
                >
                  {/* Full box shimmer */}
                  <rect
                    x="0"
                    y="0"
                    rx="10"
                    ry="10"
                    width="370"
                    height="600"
                  />{" "}
                  {/* Adjusted height */}
                </ContentLoader>
              </Col>
            </Row>
          )}
          {payRecurringData && (
            <Row className="justify-content-center">
              {/* Left Section */}
              <Col md={1} className="d-none d-md-block"></Col>

              <Col
                xs={12}
                md={5}
                className="reminder-box  d-flex flex-column h-100"
              >
                <div className="mxw-416 flex-grow-1">
                  <h4 className="greeting fs-16 fw-500 mb-0">
                    Hello {patient_first_name + " " + patient_last_name}
                  </h4>
               {pf_status <=2 &&     <p className="reminder-text fs-32 fw-bold mxw-416 mt-2 mb-3">
                    This is a reminder that your next recurring payment of
                    <span className="highlight px-1">
                      {formatCurrency(recurring_amount) || "N/A"}
                    </span>
                    is scheduled soon.
                  </p>}
             { pf_status > 2 &&      <p className="reminder-text fs-32 fw-bold mxw-416 mt-2 mb-3">
                  Your recurring payment of 
                    <span className="highlight px-1 text-danger">
                      {formatCurrency(recurring_amount) || "N/A"}
                    </span>
                    towards your debt repayment plan is <span className="text-danger fw-600">overdue</span>
                  </p>}


                  <div className="mb-0">
                      <p className="mb-0"><span className="me-2"><img src="/assets/images/cal.png" alt="" /></span>Next Payment Date : <span className="fw-500">{formatDateNew(next_payment_date)} </span> </p>
                    </div>
                  

                  {/* Service Section */}
                  <div className="service-section">
                    <div className="d-flex align-items-center gap-2">
                      <img src={doctor_image ? doctor_image : '/assets/images/dummy.png' } alt="Service Icon" className="rounded-pill" style={{width:'40px'}} />
                      <div>
                        <span className="service-label">
                          Service taken from
                        </span>
                        <h5 className="service-name fs-16 fw-bold">
                          {practice_name || doctor_name}
                        </h5>
                      </div>
                    </div>

                  

                    <div className="service-tags mt-3 d-flex flex-wrap gap-2">
                      {services.map((service: string, index: number) => (
                        <span
                          key={index}
                          className="service-badge d-flex align-items-center"
                        >
                          <img
                            src="/assets/images/greentick2.svg"
                            alt="Checked"
                            className="me-1"
                          />
                          <p className="mb-0">{service}</p>
                        </span>
                      ))}
                    </div>
                  </div>

                  {/* Auto-Debit Section */}
                  {/* <div className="auto-debit mt-4">
                  <Row className="align-items-center">
                    <Col xs={8}>
                      <p className="section-title">Scheduled Auto-Debit</p>
                      <span className="date-text">
                        Date: {formatDateNew(next_payment_date)}
                      </span>
                    </Col>
                    <Col xs={4} className="text-end p-0 ">
                      <Button
                        variant="outline-primary"
                        className="change-date fw-500"
                      >
                        Change Date
                      </Button>
                    </Col>
                  </Row>
                </div> */}

                  {/* Pay-Off Balance Section */}
                  <div className="pay-off mt-3 mb-3 mb-md-0 px-3 px-md-0">
                    <Row className="align-items-center">
                      <Col>
                        <p className="section-title">
                          Pay Off Your Balance in Full
                        </p>
                        <span className="date-text">
                          Remaining Balance:
                          {formatCurrency(patient_payoff_amount)}
                        </span>
                      </Col>
                      <Col className="text-end p-0">
                        <Button
                          className="pay-off-now fw-500"
                          onClick={() => setPayNow({ show: true, type: 1 })}
                        >
                          Pay Off Now
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>

              {/* Spacer Column for Layout Balance */}

              {/* Right Section */}
              <Col xs={12} md={5} className="d-flex justify-content-end ">
                <Card className="payment-summary flex-grow-1 mxw-416">
                  <Card.Body className="p-0">
                    <Card.Title as="h2" className="payment-amount">
                      {formatCurrency(recurring_amount)}
                    </Card.Title>
                    <Card.Text className="payment-cycle">
                      Recurring Payment {payments_paid + 1} of<span className="ms-1">{number_of_payments}</span>
                    </Card.Text>
                    <Row className="w-100 d-flex align-items-center due-indicator">
                      <Col
                        className={`line ${pf_status > 2 ? "missing" : ""}`}
                      ></Col>
                      <Col xs="auto" className="p-0">
                        <div
                          className={`due-text ${
                            pf_status > 2 ? "missing" : ""
                          }`}
                        >
                          {pf_status === 1 || pf_status === 2
                            ? `Due in ${due_days} days`
                            : "Missed Payment"}
                        </div>{" "}
                      </Col>
                      <Col
                        className={`line ${pf_status > 2 ? "missing" : ""}`}
                      ></Col>
                    </Row>
                    {/* Payment Details */}
                    <div className="details mt-3">
                      <div className="d-flex align-items-center justify-content-between pt-2">
                        <p>
                          <strong>Status</strong>
                        </p>
                        <p>
                          <span
                            className={`active-status ${
                              pf_status > 2 ? "overDue" : ""
                            }`}
                          >
                            {pf_status === 1 || pf_status === 2
                              ? "Active"
                              : "OverDue"}
                          </span>
                        </p>
                      </div>
                      <div className="d-flex align-items-center justify-content-between pt-2">
                        <p>
                          <strong>Contract ID</strong>
                        </p>
                        <p>
                          <span className="active contractId">{contract_code}</span>
                        </p>
                      </div>
                      <div className="d-flex align-items-center justify-content-between pt-2">
                        <p>
                          <strong>Plan Amount</strong>
                        </p>
                        <p>
                          <span className="discounted">
                            {formatCurrency(treatment_amount)}
                          </span>
                          {discount_due_days !== 0 && (
                            <span className="original-amount ms-2">
                              {formatCurrency(service_amount)}
                            </span>
                          )}
                        </p>
                      </div>
                      <div className="d-flex align-items-center justify-content-between pt-2">
                        <p>
                          <strong>Enrollment Date</strong>
                        </p>
                        <p>
                          <span className="enrollment-date">
                            {formatDateNew(date_added)}
                          </span>
                        </p>
                      </div>
                    </div>

                    {/* Pay Now Button */}
                    <Button
                      variant="success"
                      className="pay-now w-100 mt-3"
                      onClick={() => setPayNow({ show: true, type: 2 })}
                    >
                      Pay Recurring {formatCurrency(recurring_amount)} Now
                    </Button>
                    {pf_status <= 2 && (
                      <div
                        className="d-flex align-items-center justify-content-between mt-3 cursor discount-header"
                        onClick={() => setIsOpen(!isOpen)}
                      >
                       {discount_percent > 0 && <> <p className="discount-msg text-start mb-0">
                          <img src="/assets/images/bulb.png" alt="Bulb Icon" />
                          Keep your {discount_percent}% discount!
                        </p>
                        <p className="mb-0">
                          <i
                            className={`arrow ${isOpen ? "down" : "right"}`}
                          ></i>
                        </p></>}
                      </div>
                    )}
                    {discount_due_days > 0 && pf_status > 2 && (
                      <div className="keepDiscount">
                        <h5>
                          <img src="/assets/images/bulb.png" alt="Bulb Icon" />
                          Act Now to Keep Your {discount_percent}% Discount!{" "}
                        </h5>
                        <p>
                          Your payment is overdue! Pay within 10 days to keep
                          your discount, or you'll owe the full amount i.e{" "}
                          {formatCurrency(service_amount)}
                        </p>
                      </div>
                    )}
                    {discount_due_days === 0 && pf_status > 2 && (
                      <div className="keepDiscount">
                        <h5 className="d-flex align-items-center justify-content-center">
                          {" "}
                          <img src="/assets/images/warn.png" alt="Bulb Icon" />
                          Your discount is expired.{" "}
                        </h5>
                        <p className="mb-0">
                          Since the grace period has ended, now you owe the full
                          original amount. Make a payment as soon as possible to
                          avoid penalties.{" "}
                        </p>
                      </div>
                    )}
                    {isOpen && (
                      <div className="discount-tooltip">
                        Stay on track with timely payments to maintain your
                        reduced balance. Missing a payment will remove the
                        discount.
                      </div>
                    )}
                  </Card.Body>
                </Card>
              </Col>

              <Col md={1} className="d-none d-md-block"></Col>

            </Row>
          )}
        </Container>
      )}
      {payNow.show && (
        <PayRecurring
          back={() => setPayNow({ show: false, type: 0 })}
          data={payRecurringData}
          pay_type={payNow.type}
        />
      )}
      {!payNow.show && <CopyRightFooter />}
    </>
  );
};
export default PaymentReminder;
