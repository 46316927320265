import { useEffect } from "react";

const useGTM = (gtmId: string) => {
  useEffect(() => {
    if (document.getElementById("gtm-script")) return;

    const script = document.createElement("script");
    script.async = true;
    script.id = "gtm-script";
    script.src = `https://www.googletagmanager.com/gtm.js?id=${gtmId}`;

    // ✅ Apply nonce dynamically
    const metaNonce = document.querySelector("meta[property='csp-nonce']");
    if (metaNonce) {
      script.setAttribute("nonce", metaNonce.getAttribute("content") || "");
    }

    document.head.appendChild(script);

    (window as any).dataLayer = (window as any).dataLayer || [];
    (window as any).dataLayer.push({ event: "gtm.js", "gtm.start": new Date().getTime() });
  }, [gtmId]);
};

export default useGTM;
