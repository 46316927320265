import { FC } from "react";
import { Wrapper } from "../../ModalComponent/StripeModal";
import { Container, Row, Col, Button, Card } from 'react-bootstrap';
import AddPaymentMethod from "../AddPaymentMethod";
import { formatDate } from "../../../utils/dateformatter";
import { formatCurrency } from "../../../utils/currencyFormatter";
interface AddCardProps {}

const AddCard: FC<AddCardProps> = () => {
  const { isVisible, setIsVisible, paymentParams, handleShow,contractData } = AddPaymentMethod(3);

  return (
    <div className="text-center mt-4">
       
    <Container>
    <div className="text-center mb-4">
          <h5 className="fw-bold">Add a Card to Your Recuvery Contract with {contractData.patient_first_name + " " + contractData.patient_last_name}</h5>
          <div className="text-muted">Contract ID: {contractData.contract_id}</div>
        </div>
      <Card className="shadow-sm p-4">
        <Row className="mb-3">
          <Col md={4}>
            <strong>Service Amount</strong>
            <div>{formatCurrency(contractData.service_amount)}</div>
          </Col>
          <Col md={4}>
            <strong>Financed Amount</strong>
            <div>${contractData.financed_amount}</div>
          </Col>
          <Col md={4}>
            <strong>Downpayment Amount</strong>
            <div>${contractData.downpayment_amount}</div>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={4}>
            <strong>Recurring Amount</strong>
            <div>${contractData.recurring_amount}</div>
          </Col>
          <Col md={4}>
            <strong>Number of Payments</strong>
            <div>${contractData.number_of_payments}</div>
          </Col>
          <Col md={4}>
            <strong>Number of Missing Payments</strong>
            <div>${contractData.missing_payments}</div>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={4}>
            <strong>Remaining Payments</strong>
            <div>${contractData.remaining_payments}</div>
          </Col>
          <Col md={4}>
            <strong>Interest Rate</strong>
            <div>${contractData.interest_rate}</div>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={4}>
            <strong>Enrollment Date</strong>
            <div>{formatDate(contractData.date_added)}</div>
          </Col>
          <Col md={4}>
            <strong>Next Payment Date</strong>
            <div>{formatDate(contractData.next_payment_date)}</div>
          </Col>
        </Row>
        <Row className="text-center mb-3">
          <Col>
            <Button variant="primary" size="lg" onClick={handleShow}>Add Card</Button>
          </Col>
        </Row>
       
      </Card>
    </Container>
      <Wrapper isActive={isVisible} onHide={() => setIsVisible(false)} paymentParams={paymentParams} />
    </div>
  );
};

export default AddCard;
