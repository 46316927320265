import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../Redux/store";
import { useNavigate, useParams } from "react-router-dom";
import toastrSer from "../../service/toast.service";
import httpApiCall from "../../service/api.service";
import { setLoading } from "../../Redux/slices/loader.slice";
import { addPaymentDetail, setSelectedPaymentPlan } from "../../Redux/slices/payment.slice";
import { setItem } from "../../service/shared.service";
import { Container, Row, Col, Form } from "react-bootstrap";
import ContinueFooter from "../LayoutComponent/continueFooter/ContinueFooter";
import { formatCurrency } from "../../utils/currencyFormatter";
import { formatDateNew } from "../../utils/dateformatter";
import "./PayPartial.scss";

const PayPartial: React.FC = () => {
  let [amount, setAmount] = useState<any>(0);
  const dispatch = useDispatch<AppDispatch>();

  // const partialAmountLimit = {
  //   min: 10 / 100,
  //   max: 50 / 100,
  // };
  const { token } = useParams<{ token: string }>();
  const { doctor_country_currency_info, split_request_data }: any = useSelector(
    (state: RootState) => state.paymentDetails || {}
  );

  const [minimumAmount, setMinimumAmount] = useState<any>(0);

  const toastr = new toastrSer(true);
  const navigate = useNavigate();
  useEffect(() => {
    if (
      !split_request_data ||
      (split_request_data && !split_request_data.treatment_amount)
    ) {
      navigate("/debt-recuvery/" + token);
      return;
    }
  }, []);

  const submitAmount = () => {
    if(minimumAmount > 0){
      return;
    }
    if (Number(amount) && Number(split_request_data?.treatment_amount)) {
       let remianingAmount  = split_request_data?.treatment_amount - amount
      // const minimumCanPay =
      //   partialAmountLimit.min * Number(split_request_data?.treatment_amount);
      // const maximumCanPay =
      //   partialAmountLimit.max * Number(split_request_data?.treatment_amount);

        setItem('remianingAmount', remianingAmount);
      // if (Number(amount) > maximumCanPay) {
      //   toastr.showError(
      //     "Partial amount cannot be more than " +
      //       doctor_country_currency_info?.currency[0]?.display_logo +
      //       maximumCanPay
      //   );
      //   return;
      // } else if (Number(amount) < minimumCanPay) {
      //   toastr.showError(
      //     "Partial amount should not be less than " +
      //       doctor_country_currency_info?.currency[0]?.display_logo +
      //       minimumCanPay
      //   );
      //   return;
      // }
      callApi(amount);
    } else {
      toastr.showError("Please enter the valid amount");
      return;
    }
  };
  const goBack = () => {
    navigate("/debt-recuvery/" + token);
  };

  const callApi = async (amount: any) => {
    if (amount) {
      const apiParams = {
        type: "GET" as "GET",
        body: {
          contract_patient_token: token,
          partial_amount: amount,
        },
        api: "proposal/check_email_code",
      };
      dispatch(setLoading(true));
      const response = await httpApiCall(apiParams);
      dispatch(setLoading(false));
      console.log(response);
      if (
        !response.is_error &&
        response.split_request_data &&
        response.split_request_data.length
      ) {
        console.log(response.split_request_data[0]["plans_array"]);
        if (
          response &&
          response.split_request_data &&
          response.split_request_data.length &&
          response.split_request_data[0].contract_service_record &&
          typeof response.split_request_data[0].contract_service_record ===
            "string"
        ) {
          response.split_request_data[0].contract_service_record = JSON.parse(
            response.split_request_data[0].contract_service_record
          );
        }
        dispatch(setSelectedPaymentPlan(null));
        dispatch(addPaymentDetail(response));
        navigate("/debt-recuvery/plans/" + token);
        setItem('previous_route',1);
      }
    }
  };
  const getPartial = (event: any): void => {
    const value = Number(event.target?.value) || 0;
    setAmount(value);
    setItem('partial_amount', value);
    const maxPay = JSON.parse(split_request_data.arv2_min_max_settings || "[]");
    const minAmount = split_request_data?.treatment_amount - (maxPay[0]?.minimum_amount || 0);
  
    if (value > minAmount) {
      setMinimumAmount(minAmount);
    } else {
      setMinimumAmount(0);
    }
  };

  return (
    <>
      {doctor_country_currency_info &&
        doctor_country_currency_info.currency && (
          <Container className="text-center mt-5">
            <Row>
              <Col>
                <h3 className="mb-5">
                  Make a Partial Payment on Your Account
                  {/* <p className="fs-20 fw-700 black-40 m-0">
                (
                {doctor_country_currency_info?.currency[0]?.display_logo +
                  partialAmountLimit.min *
                    Number(split_request_data?.treatment_amount)}{" "}
                -{" "}
                {doctor_country_currency_info?.currency[0]?.display_logo +
                  partialAmountLimit.max *
                    Number(split_request_data?.treatment_amount)}
                )
              </p> */}
                </h3>

                <hr style={{ maxWidth: "90px" }} className="mx-auto" />
              </Col>
            </Row>

            {/* Outstanding Amount */}
            <Row className="justify-content-center">
              <Col xs={12} md={8}>
                <div className="mb-3 mt-3">
                  <h5 className="text-muted">Outstanding Amount</h5>
                  <h1 className="fw-bold">
                    {formatCurrency(
                      split_request_data?.treatment_amount,
                      doctor_country_currency_info &&
                        doctor_country_currency_info?.currency[0]?.display_logo
                    )}
                  </h1>
                  <p className="small partial-wrapper">
                    Pending since:
                    <span className="fw-bold">
                      {split_request_data?.date_added
                        ? formatDateNew(split_request_data.debt_duration)
                        : "yyyy-mm-dd"}{" "}
                    </span>
                    <span className="fs-16 fw-500" style={{ color: "#EB3838" }}>
                      {split_request_data?.total_overdue_duration > 0 &&
                        split_request_data?.total_overdue_duration}
                    </span>
                  </p>
                </div>
              </Col>
            </Row>

            {/* Enter Partial Amount */}
            <Row className="justify-content-center">
              <Col xs={12} md={6} lg={4}>
                <p className="text-start fw-500 pt-4">
                  Enter the partial payment amount
                </p>
                <Form onSubmit={(e) => { e.preventDefault(); submitAmount(); }}>
                  <div className="d-flex align-items-center input_field p-0">
                    <span className="d-flex justify-content-center hw-56 h-100 fs-24 fw-700">
                      {doctor_country_currency_info?.currency[0]?.display_logo}
                    </span>
                    <input
                      type="number"
                      className="input_field fs-24 ps-4 ms-2 w-100 m-0 border-0 border-start rounded-0"
                      onChange={(event: any) => getPartial(event)}
                    />
                  </div>
                  {minimumAmount > 0 && (
                    <p className="text-start text-danger">
                      Partial amount cannot be more than{" "}
                      {formatCurrency(minimumAmount)}{" "}
                    </p>
                  )}
                  {/* <div className="floating-label">
                <input
                  type="text"
                  className="styled-input w-100"
                  placeholder=" "
                  onChange={($event) => setAmount($event.target.value)}
                />
                <label>Enter amount</label>
              </div> */}
                 {  amount < split_request_data?.treatment_amount && <p className="text-start fs-14" style={{ color: "#FF5A00" }}>
                    Remaining balance:
                    {formatCurrency(split_request_data?.treatment_amount &&split_request_data?.treatment_amount - amount
                    )}
                  </p>}
                </Form>
              </Col>
            </Row>
          </Container>
        )}
      <ContinueFooter
        back={() => goBack()}
        disable={!amount || minimumAmount}
        onContinue={() => submitAmount()}
        value="Continue"
        type="submit"
        form="form-id"
      />
    </>
  );
};

export default PayPartial;
