import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { setLoading } from "../../Redux/slices/loader.slice";
import { AppDispatch, RootState } from "../../Redux/store";
import httpApiCall from "../../service/api.service";
import toastrSer from "../../service/toast.service";
import "./PaymentAgreement.scss"

const PaymentAgreement = () => {
    const toastr = new toastrSer(true);
    const dispatch = useDispatch<AppDispatch>();
    const location = useLocation();
    const { token} :any = useParams<{ token: string }>();
    const params = new URLSearchParams(location.search);
    const amount = params.get("amount");
    const type = params.get("type");
    const [content, setContent] = useState<string>("");
    const data = localStorage.getItem('recuvery_request') ? JSON.parse(localStorage.getItem('recuvery_request') || '') : '';
    const { split_request_data } = useSelector((state: RootState) => state.paymentDetails || {});


    useEffect(() => {
        if (token) {
            getPaymentAgreement();
        }
        console.log(data,"data");
        
        
    }, [token]);

    const getPaymentAgreement = async () => {
        const apiParams = {
            type: "POST" as "POST",
            body: {
                contract_patient_token: split_request_data?.contract_patient_token ? split_request_data?.contract_patient_token : token,
                rpa_form_type: type,
                to_pay_today:amount,
                settlement_type : data && data.create_plan ? 1 : data.partial_amount > 0  ? 2 :  3

            },
            api: "proposal/payment_agreement",
        };
        dispatch(setLoading(true));
        const response = await httpApiCall(apiParams);
        dispatch(setLoading(false));
        if (response.is_error === 0) {
            setContent(response.content);
        } else {
            toastr.showError(response.message);
        }
    };

    return (
        <>
            <div className="container">
                <div dangerouslySetInnerHTML={{ __html: content }} />
            </div>
        </>
    );
};

export default PaymentAgreement;
