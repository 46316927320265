import { FC } from "react";
import "./RecurringPaymentSuccessfull.scss";
import { Button, Container } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { formatCurrency } from "../../utils/currencyFormatter";
import { setLoading } from "../../Redux/slices/loader.slice";
import { useDispatch } from "react-redux";
import httpApiCall, {
  apiMethodType,
  downloadApi,
} from "../../service/api.service";
import { downloadPdf } from "../../service/shared.service";

interface RecurringPaymentSuccessfullProps {
  data?: any;
  success_type?: any;
  source_type?: any;
}

const RecurringPaymentSuccessfull: FC<RecurringPaymentSuccessfullProps> = ({
  data,
  success_type,
  source_type,
}) => {
  const { token } = useParams<{ token: string }>();
  const dispatch = useDispatch();
  const { result } = data ? data : "";
  const { recurring_amount = 0, patient_payoff_amount } = result;

  const downloadReceipt = async () => {
    const params = {
      body: {
        pay_recurring_token: token,
      },
      api: "proposal/download_contract_pdf",
      type: "GET" as apiMethodType,
    };
    dispatch(setLoading(true));
    const response = await downloadApi(params);
    downloadPdf(response, "DOWNLOAD");
    dispatch(setLoading(false));
  };

  return (
    <>
      <Container className="payment-success">
        <div className="success-icon">
          {source_type === 1 ? (
            <img src="/assets/images/initiated.png" alt="" />
          ) : (
            <img src="/assets/images/bigGreentick.svg" alt="Success" />
          )}
        </div>

        {source_type === 1 && (
          <>
            <h2>
              {" "}
              {`Payment of ${
                success_type === 1
                  ? formatCurrency(patient_payoff_amount)
                  : formatCurrency(recurring_amount)
              } initiated!`}{" "}
            </h2>
            <p>
              Your payment has been successfully initiated. You will receive a
              confirmation once the transaction is processed within 5-7 business
              days.
            </p>
          </>
        )}

        {source_type !== 1 && (
          <>
            <h2>
              {success_type === 1
                ? `Payment of ${formatCurrency(
                    patient_payoff_amount
                  )} is successfully done!`
                : `Recurring payment of ${formatCurrency(
                    recurring_amount
                  )} is successfully done!`}
            </h2>{" "}
            <p>
              {success_type === 1
                ? "Thank you for settling your account in full. We’ve received your payment. Your account is now marked as cleared."
                : "Thank you for your payment. A confirmation has been sent to your email."}
            </p>
            <hr />
            <p>
              Manage and track your contract with our intuitive customer panel.
            </p>
            <Link
              className="btn btn-success fs-18 fw-500 w-100 mt-3 py-2"
              style={{ backgroundColor: "#279F63" }}
              to={process.env.REACT_APP_CUSTOMER_URL || "/"}
              target="_blank"
            >
              Sign In to Dashboard
            </Link>
            <div
              onClick={() => downloadReceipt()}
              className="download-receipt cursor fs-16 fw-500"
              style={{ color: "#2968DD" }}
            >
              Download Receipt
            </div>
          </>
        )}
      </Container>
    </>
  );
};

export default RecurringPaymentSuccessfull;
