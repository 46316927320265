import React, { useEffect, useState } from 'react';
import html2canvas from 'html2canvas';
import { Row, Col, Button, Form, Dropdown, Container } from 'react-bootstrap';
import { saveToPayToday, setContractCode, setExixtingCard, setScanData, updatePatients } from '../../Redux/slices/payment.slice';
import { AppDispatch, RootState } from '../../Redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { patient_details, patientPaymentSourceType, paymentMatrix, PaymentMatrixType, PaymentMatrixTypeEnum, PaymentMethodType, PaymentParams, paymentSourceType } from '../../types';
import './PayNowComponent.scss';
import SignatureCanvas from "react-signature-canvas";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Wrapper } from '../ModalComponent/StripeModal';
import { ConfirmationModal } from '../ModalComponent/ConfirmationModal';
import { ExistingPaymentSources } from '../ModalComponent/ExistingPaymentSources';
import { base64ToBlob, closeExisingPaymentSourceModal, getItem, renderHtMLForTotalPayable, responseType, setItem} from '../../service/shared.service';
import httpApiCall from '../../service/api.service';
import toastrSer from '../../service/toast.service';
import { setLoading } from '../../Redux/slices/loader.slice';
import { loadStripe } from '@stripe/stripe-js';
// import { socket } from '../../service/socket.service';
import {formatDateNew } from '../../utils/dateformatter';
import ContinueFooter from '../LayoutComponent/continueFooter/ContinueFooter';
import { formatCurrency } from '../../utils/currencyFormatter';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY || '');
const PayNowComponent: React.FC = () => {
  let sigCanvas: any = {};
  const [to_pay_today, set_to_pay_today] = useState<any>();
  const [choose_plan, set_choose_plan] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isCheckedAgreement, setIsCheckedAgreement] = useState(false);
  const fontFamilyList: any = ['Dancing Script, cursive', 'Playwrite AR, cursive', 'Caveat, cursive', 'Playwrite AT, cursivee', 'Playwrite HR, cursive', '"Playwrite HU", cursive'];
  const [trimmedDataURL, setTrimmedDataURL] = useState<any>(null);
  const [selectedFont, setSelectedFont] = useState(fontFamilyList[0]);
  // const [renderHTML, setRenderHTML] = useState<React.ReactNode>(null);
  const [paymentData, setPaymentData] = useState({
    generateSignature: true,
  });

  const [iphoneDevice, setIphoneDevice] = useState(false);

  const [payment_sources, set_payment_sources] = useState<patientPaymentSourceType[]>();
  const [patient_details, set_patient_details] = useState<patient_details>();

  // for stripe modal
  const [showAddNewCardModal, setAddNewCardModal] = useState(false);


  // for confirmation modal
  const [showConfirmationModal, setConfirmationModal] = useState(false);
  const [showExistingCardModal, setShowExistingCardModal] = useState(false);
  const [paymentParams, setPaymentParams] = useState<PaymentParams>();
  const [paymentMethods, setPaymentMethods] = useState<patientPaymentSourceType[]>();
  const [selectedCards, setSelectedCards] = useState<paymentSourceType>({
    primary: paymentMatrix,
    backup: paymentMatrix,
    recurring: paymentMatrix
  });
  const [payment_matrix_type, setPaymentMatrix_type] = useState('');
  const { selectedPaymentPlan, doctor_country_currency_info, patients, split_request_data } = useSelector((state: RootState) => state.paymentDetails || {});
  const { financeOnBusinessState } = useSelector((state: RootState) => state.loaderReducer || {});
  const toastr = new toastrSer(true);
  const dispatch = useDispatch<AppDispatch>();
  const { token } = useParams<{ token: string }>();
  const navigate = useNavigate();
  const [today] = useState(new Date().toISOString().split("T")[0]);
  const [tomorrow] = useState(() => {
    const today = new Date();
    const nextDay = new Date(today);
    nextDay.setDate(today.getDate() + 1);
    return nextDay.toISOString().split("T")[0]; // Format as YYYY-MM-DD
  });
  const [recurring_date, set_recurring_date] = useState<any>();
  const [temp_recurring_date, set_temp_recurring_date] = useState<any>();
  const {addedCard} = useSelector((state: RootState) => state.paymentDetails || {})
  const createPlan =  getItem('create_plan')

  const {toPayToday : todaysettlement } = useSelector((state: RootState) => state.paymentDetails);


  useEffect(() => {
    console.log(todaysettlement,"toPayTodays");
    
    if(addedCard){

      setSelectedCards(addedCard)
    }
    if (!split_request_data || (split_request_data && !split_request_data.treatment_amount)) {
      console.log(getItem('create_plan'));
      if ((getItem('partial_amount') && Number(getItem('partial_amount'))) || (getItem('create_plan'))) {
        navigate('/debt-recuvery/plans/' + token);
      } else {
        navigate('/debt-recuvery/' + token);
      }
    }
    let newDate = new Date();
    let maxDays = 30;
    if (split_request_data && split_request_data['first_recurring_days_ar']) {
      maxDays = split_request_data['first_recurring_days_ar'];
    }
    // newDate.setDate(newDate.getDate() + maxDays); // Set the date to one month in the future

    // Format the date as 'YYYY-MM-DD'

    if(split_request_data?.downpayment_amount === 0){
      newDate.setMonth(newDate.getMonth() + 1)
    }
    else{
      newDate.setDate(newDate.getDate() + maxDays);
    }
    const formattedDate = newDate.toISOString().split('T')[0];
    set_recurring_date(formattedDate);
    set_temp_recurring_date(formattedDate);
   

    const userAgent = navigator.userAgent;
    // Check for iOS devices (iPhone, iPad)
    if (/iPhone|iPad|iPod/i.test(userAgent)) {
      setIphoneDevice(true);
    }

    if (patients && patients.payment_sources && patients.payment_sources.length) {
      set_payment_sources(patients?.payment_sources)
      if (addedCard) {
        const filterItem = patients.payment_sources.filter((item: patientPaymentSourceType) => item.last4 === addedCard.last4);
        if (!filterItem || (filterItem && filterItem.length)) {
          setExixtingCard(null);
        } else if (addedCard) {
          setSelectedCards(addedCard)
        }
      } else {
        setExixtingCard(null);
      }
    }
    if (patients && patients.patient_first_name) {
      const obj: patient_details = {
        patient_name: patients.patient_first_name + ' ' + patients.patient_last_name,
        patient_email: patients.patient_email,
        city: patients.city || '',
        state: patients.state_name || '',
        postal_code: patients.zip || '',
        line1: patients.patient_address || ''
      }
      set_patient_details(obj);
    } else if (split_request_data && split_request_data.patient_first_name) {
      const data = split_request_data;
      const obj: patient_details = {
         patient_name: data.patient_first_name + ' ' + data.patient_last_name,
         patient_email: split_request_data && split_request_data.patient_email,
         city: data.city || '',
         state: data.state || '',
         postal_code: data.zip || '',
         line1: data.patient_address || ''
      }
      set_patient_details(obj);
    }
    if (selectedPaymentPlan) {
      const renderData = renderHtMLForTotalPayable(selectedPaymentPlan, doctor_country_currency_info);
      // setRenderHTML(renderData);
    }
    // if (financeOnBusinessState && (financeOnBusinessState.contract_patient_token === token)) {
    //   dispatch(setFinancingState({contract_patient_token: ''}));
    //   navigate('/link-expire/');
    // }
    setTimeout(() => {
      saveSuggestedSignature(false)
    }, 1000)
    if (window.location.href.includes('choosePlan')) {
      let totalAmt = Number(selectedPaymentPlan?.to_pay_today);
      if (getItem('partial_amount')) {
        totalAmt += Number(getItem('partial_amount'));
      } else if (!totalAmt){
        totalAmt = Number(selectedPaymentPlan?.recurring_amount);
      }
      set_choose_plan(true);
      set_to_pay_today(totalAmt && totalAmt.toFixed(2));
      dispatch(saveToPayToday(totalAmt));
    } else {
      set_choose_plan(false);
      set_to_pay_today( split_request_data &&  split_request_data?.treatment_amount && split_request_data?.treatment_amount.toFixed(2));
      dispatch(saveToPayToday(split_request_data?.treatment_amount));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [split_request_data, selectedPaymentPlan, financeOnBusinessState])

  // const changeStep = () => {
  //   dispatch(changePreviewStep(PreviewSteps.Step1));
  // }

  // const hideEmailPopup = (params: any) => {
  //   console.log(params);
  // }

  const handleCheckboxChange = (event: any) => {
    setIsChecked(event.target.checked);
  };
  const handleAgreementCheckboxChange = (event: any) => {
    setIsCheckedAgreement(event.target.checked);
  };

  // updates the paymentData state
  const finalPaymentData = (data: any, type: string) => {
    if (type === 'date') {
      set_temp_recurring_date(data.target.value);
      // set_recurring_date(data.target.value)
    } else {
      setTrimmedDataURL(null);
      setPaymentData((stateData: any) => ({
        ...stateData,
        generateSignature: data.target.checked,
      }))
      if (data.target.checked) {
        setTimeout(() => {
          saveSuggestedSignature();
        }, 500)
      }
    }
    // console.log(paymentData.generateSignature);
  }

  const handleShow = (payment_matrix_type: string, payment_source_type: number) => {
    setPaymentMatrix_type(payment_matrix_type);
    setPaymentParams({
      payment_matrix_type: payment_matrix_type as PaymentMatrixType,
      payment_source_type,
      contract_patient_token: token
    })
    // set_payment_sources(paymentsCards)
    const banks = payment_sources?.filter((item: any) => item['payment_source_type'] === 1);
    let cards :any = payment_sources?.filter((item: any) => item['payment_source_type'] !== 1);
    let paymentSources: patientPaymentSourceType[] = [];
    if (payment_source_type === PaymentMethodType.CARD && cards && cards.length) {
      paymentSources = cards;
      setPaymentMethods(cards);
    } else if (payment_source_type === PaymentMethodType.BANK && banks && banks.length) {
      paymentSources = banks;
      setPaymentMethods(banks);
    }
    if (paymentSources && paymentSources.length) {
      setShowExistingCardModal(true);
    } else {
      setAddNewCardModal(true);
    }
  }

  const clearSignature = () => {
    sigCanvas.clear()
  }

  const getSignature = () => {
    if (sigCanvas) {
      setTrimmedDataURL(sigCanvas.getTrimmedCanvas().toDataURL());
    }
  }

  const saveSuggestedSignature = (showError: boolean = true) => {
    const pElement = document.getElementById('signature-text');
    if (pElement) {
      html2canvas(pElement).then((canvas) => {
        setTrimmedDataURL(canvas.toDataURL());
      }).catch((error) => {
        console.error('Error generating canvas:', error);
      });
    } else if (showError) {
      toastr.showError('Please select the signature');
      return;
    }
  };

  // updates payment sources and selected cards
  const setData = (params: patientPaymentSourceType) => {
    if (params) {
      // Remove duplicate cards from payment_sources based on last4
      let paymentSources = payment_sources
        ? payment_sources.filter((item: patientPaymentSourceType) => item.last4 !== params.last4)
        : [];
      paymentSources.push(params); 
      set_payment_sources(paymentSources); 
    }
  
 
    let cardTypeKey: string = "";
    if (payment_matrix_type === PaymentMatrixTypeEnum.PRIMARY) {
      cardTypeKey = "primary";
    } else if (payment_matrix_type === PaymentMatrixTypeEnum.RECURRING) {
      cardTypeKey = "recurring";
    } else if (payment_matrix_type === PaymentMatrixTypeEnum.BACKUP) {
      cardTypeKey = "backup";
    }
  
    if (cardTypeKey) {
      // Update the selected cards state and preserve existing card types
      setSelectedCards((prevCards: any) => {
        const updatedCards = {
        ...prevCards,
          [cardTypeKey]: params,
        };
        return updatedCards;
      });
      // dispatch(setExixtingCard(updatedCards));
    }
  };
  useEffect(() => {
    dispatch(setExixtingCard(selectedCards));
  }, [selectedCards, dispatch]);

  const hide = async (params: patientPaymentSourceType) => {
    if (params && !params.is_error) {
      let patientData: any = patients;
      if (patientData && patientData.payment_sources && patientData.payment_sources.length) {
        patientData = {
          ...patientData,
          payment_sources: [
            ...patientData?.payment_sources,
            params,
          ],
        }
        set_payment_sources(patientData?.payment_sources)
      }
      else {
        patientData = {
          ...patientData,
          payment_sources: [
            params
          ],
        }
      }
      dispatch(updatePatients([patientData]));
      if (payment_matrix_type === PaymentMatrixTypeEnum.PRIMARY && (!selectedCards.recurring || (selectedCards.recurring && !selectedCards.recurring['last4']))) {
        setAddNewCardModal(false);
        if (choose_plan) {
          setTimeout(() => {
            setConfirmationModal(true);
          })
        }
      } else {
        setAddNewCardModal(false);
      }
    } else {
      if (params && params.is_error === 1) {
        setAddNewCardModal(false);
        setTimeout(() => {
          setAddNewCardModal(true);
        }, 500)
      } else {
        setAddNewCardModal(false);
      }
    }
    setData(params);
  }

  const checkRecurringPrimarySame = (resp: string) => {
    if (resp === responseType.KEEPSAME) {
      setSelectedCards((cards: any) => ({
        ...cards,
        recurring: selectedCards.primary
      }));
    } else if (resp === responseType.ADDNEW) {
      setPaymentMatrix_type(PaymentMatrixTypeEnum.RECURRING);
      setPaymentParams({
        payment_matrix_type: PaymentMatrixTypeEnum.RECURRING,
        payment_source_type: PaymentMethodType.CARD,
        contract_patient_token: token
      })
      setAddNewCardModal(true);
    }
    setConfirmationModal(false);
  }

  const existingModalHide = (type: string, data: any) => {
    // check if add new card is triggered from existing cards modal
    if (closeExisingPaymentSourceModal.ADD_CARD === type || closeExisingPaymentSourceModal.ADD_BANK === type) {
      let params = JSON.parse(JSON.stringify(paymentParams));
      params.payment_source_type = (closeExisingPaymentSourceModal.ADD_CARD === type ? PaymentMethodType.CARD : PaymentMethodType.BANK);
      setPaymentParams(params);
      setTimeout(() => {
        setShowExistingCardModal(false);
        setAddNewCardModal(true);
      })
    }

    // check if modal is just closed from existing cards modal
    if (closeExisingPaymentSourceModal.CLOSE_MODAL === type) {
      setShowExistingCardModal(false);
    }

    // check if modal is closed with source id from existing cards modal
    if (closeExisingPaymentSourceModal.SELECTED_CARD === type) {
      let paymentData: any;
      if (payment_sources && payment_sources.length) {
        paymentData = payment_sources.find((item: patientPaymentSourceType) => item.source_id === data);
      }
      setData(paymentData);
      setShowExistingCardModal(false);
    }
    if(type === "ADD_BANK"){
      setShowExistingCardModal(false);
      handleShow(payment_matrix_type, PaymentMethodType.BANK);
    }
  }

  const saveFinancingError = async (error_message: string, error_type: string) => {
    const tracking_data = {
      contract_id: '',
      split_req_id: split_request_data?.split_req_id || '',
      error_message: error_message || '',
      error_type: error_type || '',
      payment_intent_id: '',
      contract_token: token,
      patient_id: '',
      pc_code: ''
    };

    await httpApiCall({ type: 'POST', api: 'proposal/save_financing_errors', body: tracking_data });
    dispatch(setLoading(false));
    toastr.showError(error_message);
    return true;
  }

  // create_contract here
  const validate = async () => {
    if ( to_pay_today && selectedCards && !selectedCards.primary.source_id) {
      toastr.showError(`Please select or add primary card`);
      return;
    }

    if (choose_plan && selectedCards && !selectedCards.recurring.source_id) {
      toastr.showError('Please select or add recurring card');
      return;
    }

    if (choose_plan && selectedCards && (selectedCards.recurring.source_id && selectedCards.backup && selectedCards.backup.source_id && selectedCards.recurring.source_id === selectedCards.backup.source_id)) {
      toastr.showError('Recurring and backup card cannot be same');
      return;
    }

    if (!isChecked) {
      toastr.showError('Please read and accept terms');
      return;
    }
    // if (!isCheckedAgreement) {
    //   toastr.showError('Please read and accept Payment Authorization Aggreement');
    //   return;
    // }

    if (!trimmedDataURL && !paymentData.generateSignature) {
      toastr.showError('Signature is missing');
      return;
    }

    // if (!choose_plan && (!split_request_data || (split_request_data && !split_request_data.patient_email))) {
    //   setEmailModal(true);
    // } else {
    // }
        set_recurring_date(temp_recurring_date)

    createContract();

  }

  const createContract = async () => {
    dispatch(setLoading(true));
    const blob = await base64ToBlob(trimmedDataURL);
    const uploadResp = await httpApiCall({ type: 'form', api: 'proposal/upload_signature', body: { patient_signature: blob } })

    console.log(blob,"patient_signature");
    
    if (!uploadResp.is_error) {
      const data: any = uploadResp;
      const specificDate = new Date(temp_recurring_date);
      const year = specificDate.getFullYear();
      const month = String(specificDate.getMonth() + 1).padStart(2, '0');
      const day = String(specificDate.getDate()).padStart(2, '0');
      const body = {
        contract_patient_token: split_request_data?.contract_patient_token ? split_request_data?.contract_patient_token : token,
        patient_signature: data['patient_signature'],
        start_date: choose_plan ? `${year}-${month}-${day}` : undefined,
        source_id: selectedCards.primary.source_id ? selectedCards.primary.source_id : selectedCards.recurring.source_id ,
        recurring_source_id: choose_plan ? selectedCards.recurring.source_id : undefined,
        backup_source_id : choose_plan && selectedCards.backup ? selectedCards.backup.source_id : undefined,
        contract_type: split_request_data && split_request_data.contract_type,
        payment_source_type: selectedCards.primary.payment_source_type ?selectedCards.primary.payment_source_type : selectedCards.recurring.payment_source_type
      }
      let api = 'proposal/create_contract';
      if (!choose_plan) {
        api = 'proposal/payoff_debt';
        dispatch(setScanData({payment_souce_type: selectedCards.primary.payment_source_type}))
        
      }
      // const data = {
      const response = await httpApiCall({ type: 'POST', api, body });
      if (!response.is_error) {
        setItem('partial_amount', null);
        setItem('create_plan', null);
        let error_message = '';
        let error_type = 'PaymentError';

        const stripe = await stripePromise;
        if (stripe && response.data && response.data.client_secret) {

          stripe.confirmCardPayment(response.data.client_secret).then(result => {
            if (result.error) {
              console.error('Payment error:', result.error);
              error_message = result.error.message || '';
              error_type = result.error.code || '';
              saveFinancingError(error_message, error_type);
            } else {
              // dispatch(setLoading(false));
              // toastr.showSuccess('Successfully created the contract');
              dispatch(setContractCode(response && response.data &&  response.data.contract_code));
              setTimeout(() => {
                dispatch(setLoading(false));
                navigate('/debt-recuvery/plan-created/' + token);
              }, 3000);
            }
          }).catch(e => {
            console.error('Payment error in catch:', e);
            error_message = e.message || e;
            saveFinancingError(error_message, e.type);
          });

        } else {
            // toastr.showSuccess('Successfully created the contract');
          // toastr.showError('Something went wrong, please try again later');
          // dispatch(setLoading(false));
          dispatch(setContractCode(response.data.contract_code))
          setTimeout(() => {
            dispatch(setLoading(false));
            navigate('/debt-recuvery/plan-created/' + token);
          }, 3000);


          return;
        }
      } else {
        dispatch(setLoading(false));
        toastr.showError(response.original_text);
      }
    } else {
      setLoading(false);
      toastr.showError('Wrong file');
    }
  }

  const changeStep = () => {
    if ((getItem('partial_amount') && Number(getItem('partial_amount'))) || (getItem('create_plan'))) {
      navigate('/debt-recuvery/customer-details/' + token);
    } else {
      navigate('/debt-recuvery/' + token);
    }
  }

  const deleteCard = (type: string) => {
    if (type === PaymentMatrixTypeEnum.BACKUP) {
      setSelectedCards((stateData: any) => ({
        ...stateData,
        backup: null,
      }))
    }
  }

  // const renderTooltip = (props: any) => (
  //   <Tooltip id="button-tooltip" {...props}>
  //     It includes Enrollment fee and Transaction fees
  //   </Tooltip>
  // );

  return (
    <>
      <Container>
        <div className={`payment-wrapper  d-flex flex-column justify-content-between ${!choose_plan && 'mxw-451'}`}>
          <div className="h-100 -overflow-auto pt-5  -hide-scroll-bar">

           <> <Row className="mb-3 m-0">
              <Col className='text-center main-header p-0'>
              { createPlan == true &&  
              <div className='palnDuration mb-3'>
              <h5 className='fs-14 '>Plan duration: <b> {selectedPaymentPlan?.month} </b>Months</h5>
              </div>
}
{           to_pay_today> 0 && createPlan == null &&     <h2 className={` ${choose_plan ? 'fs-16 fw-500 text-muted' : 'fs-24 fw-600'}`}>{choose_plan ? 'Total Payable Today' : 'Settle Your Debt in Full'}</h2>
}                {!choose_plan && <>
                  <p className=" fw-400 fs-16">
                    Before proceeding, verify all details and set payment method.
                  </p>
                  <div className='mx-auto pt-3' style={{width: '64px'}} >
                    <hr className='m-0 mt-3' />
                  </div>
                </>
                }
              </Col>
            </Row>

            <Row className="mb-3 m-0 align-items-center mid-header">
              <Col className=" d-flex align-items-center flex-column">
                <p className="fs-16 fw-400 m-0 mb-3 d-sm-none">
                  Payment Method
                </p>
                {!choose_plan && <p className="fs-16 fw-400 m-0 d-none d-sm-flex mt-1 mb-3 Outstanding ">Outstanding balance
                  {/* {choose_plan && <OverlayTrigger
                  placement="right"
                  delay={{ show: 250, hide: 400 }}
                  overlay={TooltipFn(renderHTML)}>
                    <img className="ms-2 cursor" src="/assets/images/info-circle.svg" style={{width: '20px'}} alt=''/>
                </OverlayTrigger>} */}
                </p>}
                { to_pay_today> 0 && <p className="fs-46 fw-700 mb-4 d-none d-sm-flex">
                  {formatCurrency(to_pay_today,doctor_country_currency_info?.currency?.[0]?.display_logo)}
                  <br></br>
                </p>}
                {choose_plan &&   <> 
                  {createPlan == null && <p className='fs-18 fw-500 mb-0'>Monthly Recurring: <span>{formatCurrency(selectedPaymentPlan?.recurring_amount)}</span> </p>}
                { createPlan == true &&<p className='fs-18 fw-500 mb-0'>1st Recurring Payment Due Today </p>}
                  <p style={{ color: '#656B73' }} className='fs-14 fw-400 d-flex align-items-center'>{getItem('partial_amount') ? ' First Recurring Date : ' : ' Next Payment Date : '} 
                     <span className='ms-1'> {formatDateNew(temp_recurring_date)}  
                       {/* {recurring_date && <Form.Control value={temp_recurring_date}  min={tomorrow} max={recurring_date} className=" cursor w-auto border-0 fs-14 fw-400 p-0 ps-2" type="date" onChange={($event) => finalPaymentData($event, 'date')} />} */}
                  </span> 
                  </p> </>}
                {!choose_plan && <div className='since-wrapper'> <span className='since'>Pending since:</span> <span className='debtdate'>{formatDateNew(split_request_data && split_request_data.debt_duration)}</span> <span className='fs-16 fw-500' style={{ color: "#EB3838" }}>  {split_request_data?.total_overdue_duration > 0 && (
                  split_request_data?.total_overdue_duration
                )}</span> </div>}
              </Col>
            </Row>
          </>


            {choose_plan && to_pay_today >0 &&  <hr />
            }

            {!choose_plan &&
              <div className="mxw-460 mx-auto mb-3 text-start paymentType">
                <label className='w-100'>How would you like to pay ?</label>
                <div>
                  {(!selectedCards.primary || (selectedCards.primary && selectedCards.primary.last4 === 0)) &&
                    <>
                      {/* {iphoneDevice && <Button
                        variant="outline"
                        className="btn  fw-800 d-flex align-items-center bg-black text-white"
                      // onClick={() => handleShow()}
                      >
                        <img src="/assets/images/apple-icon.svg" className=' hw-20' alt="" />
                        Pay
                      </Button>} */}
                      <Button
                        variant="outline"
                        className="btn  fw-500 d-flex align-items-center d-flex justify-content-between mb-2 w-100"
                        onClick={() => handleShow(PaymentMatrixTypeEnum.PRIMARY, PaymentMethodType.CARD)}
                      >
                        {/* <img src="/assets/images/card-icon.svg" className='me-2 hw-20' alt="" /> */}
                        <span>Credit/Debit Card</span>
                        <span className='card-images'>
                          <img src='/assets/images/card-type/Mastercard.svg' alt='' />
                          <img src='/assets/images/card-type/Visa.svg' alt='' />
                        </span>
                      </Button>

                      <Button
                        variant="outline"
                        className="btn w-100  fw-500 d-flex align-items-center d-flex justify-content-between"
                        onClick={() => handleShow(PaymentMatrixTypeEnum.PRIMARY, PaymentMethodType.BANK)}
                      >
                        {/* <img src="/assets/images/card-icon.svg" className='me-2 hw-20' alt="" /> */}
                        <span>Bank Transfer</span>
                        <span className='card-images'>
                          <img src='/assets/images/bank-icon.svg' alt='' />
                        </span>
                      </Button>
                      {/* <Button
                      variant="outline"
                      className="btn fw-500 d-flex align-items-center text-primary border-primary"
                      onClick={() => handleShow(PaymentMatrixTypeEnum.PRIMARY, PaymentMethodType.BANK)}
                    >
                      <img src="/assets/images/bank-icon.svg" className='me-2 hw-20' alt="" />
                      Bank
                    </Button> */}
                    </>
                  }
                  {selectedCards.primary && selectedCards.primary.last4 !== 0 && (
                    <div className="d-flex border-top border-bottom -radius-4 align-items-center justify-content-between py-4">
                      <div className='mxw-416 w-100'>
                          <h5 className='fw-500 fs-16 mb-1'>Payment Method </h5>
                          <p className='fw-400 fs-12 mb-0 text-muted mxw-416  w-100'>This payment method will be used for payment. </p>
                      </div>
                      <div className='d-flex'>
                      <img alt="" src={
                          selectedCards.primary.payment_source_type === 1 
                            ? "/assets/images/bank-icon.svg" 
                            : `/assets/images/card-type/${selectedCards?.primary?.brand}.svg`
                        }
                        className="me-2"
                        style={{ width: "24px", height: "24px" }}
                      />
                        <p className="m-0 fs-14 fw-600 d-flex align-items-center">
                          <span className="me-2">****</span>
                          {selectedCards.primary.last4}
                        </p>
                        <span>   <img alt="" src="/assets/images/edit-pencil.svg" className="ms-2 cursor" onClick={() =>
                        handleShow(
                          PaymentMatrixTypeEnum.PRIMARY,
                          PaymentMethodType.CARD
                        )
                      }
                      /></span>
                      </div>
                   
                    </div>
                  )}

                </div>
              </div>
            }

            {choose_plan && <> 
              {to_pay_today>0 && (<><Row className="mb-3 m-0 align-items-center">
              <Col className="p-0 mb-3">
                <p className="fs-16 fw-500 m-0 " style={{ color: "#2B3038" }}>
                  Payment Method
                </p>

                <p className="fs-14 m-0  d-none d-sm-flex" style={{ color: "#5A5E64" }} >
                  This payment method will be used for today's payment.
                </p>
              </Col>
              <Col className="d-flex justify-content-end">
                {(!selectedCards.primary || (selectedCards.primary && selectedCards.primary.last4 === 0)) && <div className='d-flex align-items-center'>
                  {iphoneDevice && <Button
                    variant="outline"
                    className="btn me-3 fw-800 d-flex align-items-center bg-black text-white"
                  // onClick={() => handleShow()}
                  >
                    <img src="/assets/images/apple-icon.svg" className='me-2 hw-20' alt="" />
                    Pay
                  </Button>}
                  <Button
                    variant="outline"
                    className="btn me-3 fw-500 d-flex align-items-center bg-primary text-center border-primary bg-transparent"
                    onClick={() => handleShow(PaymentMatrixTypeEnum.PRIMARY, PaymentMethodType.CARD)}
                  >
                    {/* <img src="/assets/images/card-icon.svg" className='me-2 hw-20' alt="" /> */}
                    <p className=' mb-0' style={{ color: '#1581E5' }}>Add Card</p>
                  </Button>
                  <Button
                    variant="outline"
                    className="btn fw-500 d-flex align-items-center text-primary border-primary"
                    onClick={() => handleShow(PaymentMatrixTypeEnum.PRIMARY, PaymentMethodType.BANK)}
                  >
                    <img src="/assets/images/bank-icon.svg" className='me-2 hw-20' alt="" />
                    Bank
                  </Button>
                </div>}
                {selectedCards.primary && selectedCards.primary.last4 !== 0 && (
                  <div className="d-flex p-1 border radius-4 align-items-center">
                    <img alt="" src={
                          selectedCards.primary.payment_source_type === 1 
                            ? "/assets/images/bank-icon.svg" 
                            : `/assets/images/card-type/${selectedCards?.primary?.brand}.svg`
                        }
                        className="me-2"
                        style={{ width: "30px", height: "18px" }}
                      />
                    <p className="m-0 fs-14 fw-600 d-flex align-items-center">
                      {/* <span className="me-2">****</span> */}
                      {selectedCards.primary.last4}
                    </p>
                    <img alt="" src="/assets/images/edit-pencil.svg" className="ms-2 cursor" onClick={() =>
                      handleShow(
                        PaymentMatrixTypeEnum.PRIMARY,
                        PaymentMethodType.CARD
                      )
                    }
                    />
                  </div>
                )}
              </Col>
            </Row>
            

              <hr />
              </>)}
              <Row className="mb-3 m-0 align-items-center">
                <Col className="p-0 mb-3">
                  <p className="fs-16 fw-500 m-0" style={{ color: "#2B3038" }}>
                    Recurring Payment Method
                  </p>

                  <p className="fs-14 m-0  d-none d-sm-flex" style={{ color: "#5A5E64" }}>
                    All future recurring payments will be charged to this payment method.
                  </p>
                </Col>
                <Col className="d-flex justify-content-end">
                  {(!selectedCards.recurring || (selectedCards.recurring && selectedCards.recurring.last4 === 0)) && <div className='d-flex align-items-center'>
                    {/* {iphoneDevice && <Button
                      variant="outline"
                      className="btn me-3 fw-800 d-flex align-items-center bg-black text-white">
                      <img src="/assets/images/apple-icon.svg" className='me-2 hw-20' alt="" />
                      Pay
                    </Button>} */}
                    <Button
                      variant="outline"
                      style={{ color: '#1581E5' }}
                      className="btn me-3 fw-500 d-flex align-items-center bg-primary border-primary bg-transparent"
                      onClick={() => handleShow(PaymentMatrixTypeEnum.RECURRING, PaymentMethodType.CARD)}>
                      {/* <img src="/assets/images/card-icon.svg" className='me-2 hw-20' alt="" /> */}
                      Add Card
                    </Button>
                    <Button
                      variant="outline"
                      className="btn fw-500 d-flex align-items-center text-primary border-primary"
                      onClick={() => handleShow(PaymentMatrixTypeEnum.RECURRING, PaymentMethodType.BANK)}
                    >
                      <img src="/assets/images/bank-icon.svg" className='me-2 hw-20' alt="" />
                      Bank
                    </Button>
                  </div>}
                  {selectedCards.recurring && selectedCards.recurring.last4 !== 0 && (
                    <div className="d-flex p-1 border radius-4 align-items-center">
                     <img alt="" src={
                          selectedCards.recurring.payment_source_type === 1 
                            ? "/assets/images/bank-icon.svg" 
                            : `/assets/images/card-type/${selectedCards?.recurring?.brand}.svg`
                        }
                        className="me-2"
                        style={{ width: "30px", height: "18px" }}
                      />
                      <p className="m-0 fs-14 fw-600 d-flex align-items-center">
                        {/* <span className="me-2">****</span> */}
                        {selectedCards.recurring.last4}
                      </p>
                      <img src="/assets/images/edit-pencil.svg" className="ms-2 cursor" alt="" onClick={() =>
                        handleShow(
                          PaymentMatrixTypeEnum.RECURRING,
                          PaymentMethodType.CARD
                        )
                      }
                      />
                    </div>
                  )}

                </Col>
              </Row>

              <hr />

              {/* <Row className="mb-3 m-0">
            <Col className="d-flex align-items-center p-0 mb-3">
              <p className="fs-18 fw-500 m-0">Recurring Date </p>
            </Col>
            <Col className="d-flex justify-content-end">
              {recurring_date && <Form.Control value={recurring_date} min={today} max={recurring_date} className="w-auto" type="date" onChange={($event) => finalPaymentData($event, 'date')} />}
            </Col>
          </Row>

          <hr /> */}


              <Row className="mb-3 m-0 align-items-center">
                <Col className="p-0 mb-3">
                  <p className="fs-16 fw-500 m-0" style={{ color: "#2B3038" }}>Backup Payment Method <span style={{ color: "#FD8105" }} > (Recommended) </span> </p>
                  <p className="fs-14 m-0  d-none d-sm-flex" style={{ color: "#5A5E64" }}>
                    A backup payment method is needed in case the primary payment method fails during a payment attempt.
                  </p>
                </Col>
                <Col className="d-flex justify-content-end">
                  {(!selectedCards.backup || (selectedCards.backup && selectedCards.backup.last4 === 0)) && <div className='d-flex align-items-center'>
                    {/* {iphoneDevice && <Button
                      variant="outline"
                      className="btn me-3 fw-800 d-flex align-items-center bg-black text-white">
                      <img src="/assets/images/apple-icon.svg" className='me-2 hw-20' alt="" />
                      Pay
                    </Button>} */}
                    <Button
                      variant="outline"
                      style={{ color: '#1581E5' }}
                      className="btn me-3 fw-500 d-flex align-items-center bg-primary  border-primary bg-transparent"
                      onClick={() => handleShow(PaymentMatrixTypeEnum.BACKUP, PaymentMethodType.CARD)}>
                      {/* <img src="/assets/images/card-icon.svg" className='me-2 hw-20' alt="" /> */}
                      Add Card
                    </Button>
                    <Button
                      variant="outline"
                      className="btn fw-500 d-flex align-items-center text-primary border-primary"
                      onClick={() => handleShow(PaymentMatrixTypeEnum.BACKUP, PaymentMethodType.BANK)}
                    >
                      <img src="/assets/images/bank-icon.svg" className='me-2 hw-20' alt="" />
                      Bank
                    </Button>
                  </div>}
                  {selectedCards.backup && selectedCards.backup.last4 !== 0 && (
                    <div className="d-flex p-1 border radius-4 align-items-center">
                      <img alt="" src={
                          selectedCards.backup.payment_source_type === 1 
                            ? "/assets/images/bank-icon.svg" 
                            : `/assets/images/card-type/${selectedCards?.backup?.brand}.svg`
                        }
                        className="me-2"
                        style={{ width: "30px", height: "18px" }}
                      />
                      <p className="m-0 fs-14 fw-600 d-flex align-items-center">
                        {/* <span className="me-2">****</span> */}
                        {selectedCards.backup.last4}
                      </p>
                      <img src="/assets/images/edit-pencil.svg" className="ms-2 cursor" alt="" onClick={() =>
                        handleShow(
                          PaymentMatrixTypeEnum.BACKUP,
                          PaymentMethodType.CARD
                        )
                      }
                      />
                      <img src="/assets/images/delete.png" className="ms-2 cursor" style={{width: '20px', height: '20px'}} alt="" onClick={() =>
                        deleteCard(
                          PaymentMatrixTypeEnum.BACKUP
                        )
                      }
                      />
                    </div>
                  )}

                </Col>
              </Row>

              <hr /></>}

            <Row className="mb-3 m-0">
              <Col className="d-flex align-itwms-center justify-content-between p-0">
                <p className="fs-18 fw-600 m-0">Customer Signature</p>
                <span className="d-flex align-items-center">
                  <label className="switch me-2">
                    <input type="checkbox" id="toggleSwitch" checked={paymentData.generateSignature} onChange={($event) => finalPaymentData($event, 'signature')} />
                    <span className="slider"></span>
                  </label>
                  <label className="fs-14 fw-400 m-0">Generate Signature</label>
                </span>
              </Col>
            </Row>
            <Row className="mb-3 m-0">
              <Col className='p-0'>
                <div
                  className="signature-panel radius-8 position-relative d-flex align-items-center justify-content-center"
                  style={{ border: "2px solid #ced4da", height: '300px', }} >
                  {paymentData.generateSignature ? (
                    <>
                      <div className='h-100 w-100 d-flex align-items-center justify-content-center'>

                        <p className='fs-60' id="signature-text" style={{ fontFamily: selectedFont, transform: 'rotate(-15deg)' }}>{(patients?.patient_first_name || split_request_data?.patient_first_name || '') + " " + (patients?.patient_last_name || split_request_data?.patient_last_name || '') || 'Doctor Name'}</p>
                      </div>

                      <Dropdown className="position-absolute  cursor  rounded-circle hw-40" style={{ right: '25px', bottom: '25px' }}>
                        <Dropdown.Toggle className='bg-transparent border-0 rounded-circle' style={{ minWidth: 'unset' }} >
                          <img
                            src="/assets/images/fonts-icon.svg"
                            alt=""
                            id="dropdown-basic"
                            className="position-absolute bottom-0 end-0 cursor"
                          />
                        </Dropdown.Toggle>

                        <Dropdown.Menu className='decoration-none p-3 radius-8 border bg-white'>
                          {fontFamilyList && fontFamilyList.map((item: any, index: number) => (
                            <Dropdown.Item key={index} onMouseEnter={(event: any) => {
                              // console.log(fontFamilyList.indexOf(event.target['textContent']))
                              setSelectedFont(event.target['textContent']);
                              saveSuggestedSignature();
                            }
                            }
                              className={"d-flex justify-content-between cursor " + (selectedFont === item ? 'active' : '')}
                            >{item}</Dropdown.Item>
                          )
                          )}
                        </Dropdown.Menu>
                      </Dropdown>
                    </>
                  ) : (
                    <div className=''>
                      <SignatureCanvas ref={(ref) => { sigCanvas = ref; }} penColor="black" canvasProps={{ className: "sigCanvas w-100" , style: { width:'100%',  height:'300px'} }} onEnd={getSignature} />
                      <img src="/assets/images/eraser-icon.svg" alt="" className="position-absolute cursor" style={{ right: "25px", bottom: "25px" }} onClick={clearSignature} />
                    </div>
                  )}
                </div>

              </Col>
            </Row>
            <Row className="mb-3 m-0 p-0 position-relative">
  <Col className="ps-1 pe-0">
  <Form.Check
  id="agreement-checkbox"
  checked={isChecked}
  className="custom-checkbox"
  onChange={handleCheckboxChange}
  type="checkbox"
  aria-label="Agreement Checkbox"
  label={
    <label className="" htmlFor="agreement-checkbox">
      <b className="fw-500">I agree to the following:</b>
      <ul className="fs-14 ps-3">
        <li>
          By proceeding, I acknowledge and agree that my electronic signature carries the same legal validity and enforceability as a handwritten signature for this contract/payment.
        </li>
        <li>
          I've read & agreed to the  
          <Link 
            target="_blank" 
            className="ms-1" 
            to={`/debt-recuvery/paymentAgreement/${split_request_data?.contract_patient_token || token}?type=1`}
          >
            Settlement Agreement
          </Link> 
          <span className="mx-1">&</span>  
          <Link 
            target="_blank" 
            to={`/debt-recuvery/paymentAgreement/${split_request_data?.contract_patient_token || token}?type=3`}
          >
            Payment Authorization Agreement
          </Link>
        </li>
      </ul>
    </label>
  }
/>
  </Col>
</Row>

              

          
            {/* <Row className="mb-3 m-0 p-0">
              <Col className='ps-1'>
                <Form.Check
                   id="agreement-checkbox2"
                  checked={isCheckedAgreement}
                  className=''
                  onChange={handleAgreementCheckboxChange}
                  type="checkbox"
                  label={
                    <label htmlFor="agreement-checkbox2">
                    I've read & agreed to the  
                    <Link target="_blank" className='ms-1' to={`/debt-recuvery/paymentAgreement/${split_request_data?.contract_patient_token ? split_request_data?.contract_patient_token : token}?type=1&amount=${todaysettlement}`}>
                        Settlement Agreement
                    </Link> 
                    <span className='mx-1'>&</span>  
                    <Link target="_blank" to={`/debt-recuvery/paymentAgreement/${split_request_data?.contract_patient_token ? split_request_data?.contract_patient_token : token}?type=3&amount=${todaysettlement}`}>
                        Payment Authorization Agreement
                    </Link>
                  </label>
                  }
                />
              </Col>
            </Row> */}
          </div>

        </div>
      </Container>

      <ContinueFooter back={() => changeStep()} onContinue={() => validate()} disable={false} value={ to_pay_today > 0 ? `Proceed to Pay ${formatCurrency(to_pay_today,doctor_country_currency_info?.currency?.[0]?.display_logo)  || ""}`: "Confirm"}
      />


      {showConfirmationModal && <ConfirmationModal isActive={showConfirmationModal} onHide={(params: any) => checkRecurringPrimarySame(params)} paymentParams={selectedCards.primary} />}

      {showAddNewCardModal && <Wrapper isActive={showAddNewCardModal} onHide={(params) => hide(params)} paymentParams={paymentParams} patient_details={patient_details} />}

      {showExistingCardModal && <ExistingPaymentSources isActive={showExistingCardModal} onHide={(type: string, data: any) => existingModalHide(type, data)} patient_name={patients ? patients.patient_first_name + ' ' + patients.patient_last_name : ''} patient_sources={paymentMethods || []} />}

      {/* {showEmailModal && <EmailModal isActive={showEmailModal} onHide={(params: any) => hideEmailPopup(params)} />} */}
    </>
  );
};

export default PayNowComponent;
