import { FC, useEffect, useState } from "react";
import { Card } from "react-bootstrap";

import "./PayRecurring.scss";
import ContinueFooter from "../LayoutComponent/continueFooter/ContinueFooter";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../Redux/store";
import { formatCurrency } from "../../utils/currencyFormatter";
import { Wrapper } from "../ModalComponent/StripeModal";
import {
  patient_details,
  patientPaymentSourceType,
  paymentMatrix,
  PaymentMatrixType,
  PaymentMatrixTypeEnum,
  PaymentMethodType,
  PaymentParams,
  paymentSourceType,
} from "../../types";
import { ExistingPaymentSources } from "../ModalComponent/ExistingPaymentSources";
import { ConfirmationModal } from "../ModalComponent/ConfirmationModal";
import {
  closeExisingPaymentSourceModal,
  responseType,
} from "../../service/shared.service";
import {
  setExixtingCard,
  updatePatients,
} from "../../Redux/slices/payment.slice";
import httpApiCall from "../../service/api.service";
import RecurringPaymentSuccessfull from "../RecurringPaymentSuccessfull/RecurringPaymentSuccessfull";
import { loadStripe } from "@stripe/stripe-js";
import { setLoading } from "../../Redux/slices/loader.slice";
import toastrSer from "../../service/toast.service";
import CopyRightFooter from "../LayoutComponent/copyrightfooter/CopyRightFooter";

interface PayRecurringProps {
  back: any;
  data: any;
  pay_type: any;
}

const PayRecurring: FC<PayRecurringProps> = ({ back, data, pay_type }) => {
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY || "");
  const toastr = new toastrSer(true);
  const { token } = useParams<{ token: string }>();
  const [paymentData, setPaymentData]: any = useState("");
  const [choose_plan, set_choose_plan] = useState<boolean>(false);
  const [showAddNewCardModal, setShowAddNewCardModal] = useState(false);
  const [showExistingCardModal, setShowExistingCardModal] = useState(false);
  const [showConfirmationModal, setConfirmationModal] = useState(false);
  const [selectedCards, setSelectedCards] = useState<paymentSourceType>({
    primary: paymentMatrix,
    backup: paymentMatrix,
    recurring: paymentMatrix,
  });
  const [paymentMethods, setPaymentMethods] =
    useState<patientPaymentSourceType[]>();
  const [payment_matrix_type, setPaymentMatrix_type] = useState("");
  const [paymentParams, setPaymentParams] = useState<any>();
  const [paymentSources, setPaymentSources] =
    useState<patientPaymentSourceType[]>();
  const [patient_details, set_patient_details] = useState<patient_details>();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [paySuccesfull, setPaySuccesfull] = useState(false);

  const { result } = data;
  console.log(result, "result");
  console.log(data, "data00000");

  const {
    patient_first_name,
    patient_last_name,
    recurring_amount = 0,
    payment_sources,
    payment_source_type = 0,
    last4 = "N/A",
    number_of_payments = 0,
    due_days = 0,
    payments_paid = 0,
    patient_id,
    patient_email,
    city,
    patient_address,
    state,
    zip,
    patient_payoff_amount,
    pf_id,
  } = result;

  useEffect(() => {
    dispatch(setExixtingCard(selectedCards));
    const obj: patient_details = {
      patient_name: patient_first_name + " " + patient_last_name,
      patient_email: patient_email,
      city: city || "",
      state: state || "",
      postal_code: zip || "",
      line1: patient_address || "",
    };
    set_patient_details(obj);

    setPaymentMethods(payment_sources);
  }, [selectedCards, dispatch]);

  const handleShow = (
    payment_matrix_type: string,
    payment_source_type: number
  ) => {
    setPaymentMatrix_type(payment_matrix_type);
    setPaymentParams({
      payment_matrix_type: payment_matrix_type as PaymentMatrixType,
      payment_source_type,
      patient_id: patient_id,
    });
    // set_payment_sources(paymentsCards)
    const banks = payment_sources?.filter(
      (item: any) => item["payment_source_type"] === 1
    );
    let cards: any = payment_sources;
    let paymentSources: patientPaymentSourceType[] = [];
    if (
      payment_source_type === PaymentMethodType.CARD &&
      cards &&
      cards.length
    ) {
      paymentSources = cards;
      setPaymentMethods(cards);
    } else if (
      payment_source_type === PaymentMethodType.BANK &&
      banks &&
      banks.length
    ) {
      paymentSources = banks;
      setPaymentMethods(banks);
    }
    if (paymentSources && paymentSources.length) {
      setShowExistingCardModal(true);
    } else {
      setShowAddNewCardModal(true);
    }
  };

  const hide = async (params: patientPaymentSourceType) => {
    if (params && !params.is_error) {
      if (
        payment_matrix_type === PaymentMatrixTypeEnum.PRIMARY &&
        (!selectedCards.recurring ||
          (selectedCards.recurring && !selectedCards.recurring["last4"]))
      ) {
        setShowAddNewCardModal(false);
        // if (choose_plan) {
        //   setTimeout(() => {
        //     setConfirmationModal(true);
        //   })
        // }
      } else {
        setShowAddNewCardModal(false);
      }
    } else {
      if (params && params.is_error === 1) {
        setShowAddNewCardModal(false);
        setTimeout(() => {
          setShowAddNewCardModal(true);
        }, 500);
      } else {
        setShowAddNewCardModal(false);
      }
    }
    setData(params);
  };
  const existingModalHide = (type: string, data: any) => {
    // check if add new card is triggered from existing cards modal
    if (
      closeExisingPaymentSourceModal.ADD_CARD === type ||
      closeExisingPaymentSourceModal.ADD_BANK === type
    ) {
      let params = JSON.parse(JSON.stringify(paymentParams));
      params.payment_source_type =
        closeExisingPaymentSourceModal.ADD_CARD === type
          ? PaymentMethodType.CARD
          : PaymentMethodType.BANK;
      setPaymentParams(params);
      setTimeout(() => {
        setShowExistingCardModal(false);
        setShowAddNewCardModal(true);
      });
    }

    // check if modal is just closed from existing cards modal
    if (closeExisingPaymentSourceModal.CLOSE_MODAL === type) {
      setShowExistingCardModal(false);
    }

    // check if modal is closed with source id from existing cards modal
    if (closeExisingPaymentSourceModal.SELECTED_CARD === type) {
      let paymentData: any;
      if (payment_sources && payment_sources.length) {
        paymentData = payment_sources.find(
          (item: patientPaymentSourceType) => item.source_id === data
        );
      }
      setData(paymentData);
      setShowExistingCardModal(false);
    }
    if (type === "ADD_BANK") {
      setShowExistingCardModal(false);
      handleShow(payment_matrix_type, PaymentMethodType.BANK);
    }
  };
  const setData = (params: patientPaymentSourceType) => {
    if (params) {
      // Remove duplicate cards from payment_sources based on last4
      let paymentSources = payment_sources
        ? payment_sources.filter(
            (item: patientPaymentSourceType) => item.last4 !== params.last4
          )
        : [];
      paymentSources.push(params);
      setPaymentSources(paymentSources);
    }

    let cardTypeKey: string = "";
    if (payment_matrix_type === PaymentMatrixTypeEnum.PRIMARY) {
      cardTypeKey = "primary";
    } else if (payment_matrix_type === PaymentMatrixTypeEnum.RECURRING) {
      cardTypeKey = "recurring";
    } else if (payment_matrix_type === PaymentMatrixTypeEnum.BACKUP) {
      cardTypeKey = "backup";
    }

    if (cardTypeKey) {
      // Update the selected cards state and preserve existing card types
      setSelectedCards((prevCards: any) => {
        const updatedCards = {
          ...prevCards,
          [cardTypeKey]: params,
        };
        return updatedCards;
      });
      // dispatch(setExixtingCard(updatedCards));
    }
  };

  const payRecurring = async () => {
    dispatch(setLoading(true));

    const body = {
      pay_recurring_token: token,
      source_id: selectedCards && selectedCards.primary.source_id,
      payment_source_type:
        selectedCards && selectedCards.primary.payment_source_type,
      pf_id: pf_id,
    };
    const api =
      pay_type === 1 ? "proposal/payoff_contract" : "proposal/pay_recurring";

    try {
      const response = await httpApiCall({
        type: "POST",
        api: api,
        body: body,
      });
      setPaymentData(response);
      if (response.is_error === 0) {
        let error_message = "";
        const stripe = await stripePromise;

        if (stripe && response.data && response.data.client_secret) {
          stripe
            .confirmCardPayment(response.data.client_secret)
            .then((result) => {
              dispatch(setLoading(false));
              if (result.error) {
                console.error("Payment error:", result.error);
                error_message = result.error.message || "";
                toastr.showError(error_message);
              } else {
                // setTimeout(() => {
                  setPaySuccesfull(true);
                  dispatch(setLoading(false));
                // }, 4000);
              }
            })
            .catch((e) => {
              console.error("Payment error in catch:", e);
              error_message = e.message || e;
            });
        } else {
          // setTimeout(() => {
            dispatch(setLoading(false));
            setPaySuccesfull(true);
          // }, 3000);

          return;
        }
        // successfull();
        // back();
      } else {
        dispatch(setLoading(false));
      }
    } catch (error) {
      console.error("Error fetching pay recurring data:", error);
    } finally {
    }
  };
  return (
    <>
      {!paySuccesfull && (
        <>
          <div className="payment-reminder-container">
            {pay_type !== 1 ? (
              <h2 className="title">Pay Recurring Now</h2>
            ) : (
              <h2 className="title">Settle Your Account Completely</h2>
            )}
            {pay_type !== 1 ? (
              <p className="subtitle pb-3">
                Paying now? Great! Your scheduled auto-payment will be skipped
                for this billing cycle.
              </p>
            ) : (
              <p className="subtitle pb-3">
                You're almost there! Complete this payment to settle your
                balance in full—no further payments needed.
              </p>
            )}
            <hr className="w-25 mx-auto" />

            <div className="payment-info">
              {pay_type !== 1 ? (
                <p className="recurring-text">
                  Recurring Payment {payments_paid + 1} of {number_of_payments}
                </p>
              ) : (
                <p className="recurring-text">Pay off your balance in full</p>
              )}
              <h1 className="amount">
                {pay_type === 1
                  ? formatCurrency(patient_payoff_amount)
                  : formatCurrency(recurring_amount)}
              </h1>
              {pay_type !== 1 && (
                <span className="due-badge">
                  {due_days > 0 ? `Due in ${due_days} days` : "OverDue"}
                </span>
              )}
            </div>

            <hr />

            <div className="payment-method pb-3">
              <p className="label">Payment Method</p>
              <div className="d-flex align-items-center justify-content-between w-100">
                <p className="description mb-0 mxw-416">
                  Select payment method to pay your recurring amount.
                </p>

                <div className="payment-card cursor">
                  {selectedCards && selectedCards.primary.last4 ? (
                    <>
                      <span className="card-logo w-100">
                        <img
                          style={{ width: "32px" }}
                          alt=""
                          src={
                            selectedCards &&
                            selectedCards.primary.payment_source_type === 1
                              ? "/assets/images/bank-icon.svg"
                              : `/assets/images/card-type/${selectedCards?.primary?.brand}.svg`
                          }
                        />
                      </span>
                      <p className="card-number mb-0">
                        ***{selectedCards && selectedCards.primary.last4}
                      </p>
                      <span
                        className="edit-icon"
                        onClick={() =>
                          handleShow(
                            PaymentMatrixTypeEnum.PRIMARY,
                            PaymentMethodType.CARD
                          )
                        }
                      >
                        <img
                          alt=""
                          src="/assets/images/edit-pencil.svg"
                          className="ms-2 cursor"
                        ></img>
                      </span>
                    </>
                  ) : (
                    <p
                      className="mb-0"
                      onClick={() =>
                        handleShow(
                          PaymentMatrixTypeEnum.PRIMARY,
                          PaymentMethodType.CARD
                        )
                      }
                    >
                      Add method
                    </p>
                  )}
                </div>
              </div>
            </div>
            <hr />
          </div>
          <ContinueFooter
            back={back}
            onContinue={() => payRecurring()}
            value={`Confirm & Pay ${pay_type === 1
              ? formatCurrency(patient_payoff_amount)
              : formatCurrency(recurring_amount)}`}
            disable={!(selectedCards && selectedCards?.primary.last4)}
          ></ContinueFooter>
        </>
      )}

      {showAddNewCardModal && (
        <Wrapper
          isActive={showAddNewCardModal}
          onHide={(params) => hide(params)}
          paymentParams={paymentParams}
          patient_details={patient_details}
        />
      )}
      {showExistingCardModal && (
        <ExistingPaymentSources
          isActive={showExistingCardModal}
          onHide={(type: string, data: any) => existingModalHide(type, data)}
          patient_name={patient_first_name + " " + patient_last_name}
          patient_sources={paymentMethods || []}
          type="Remminders"
        />
      )}

      {paySuccesfull && (
        <RecurringPaymentSuccessfull
          data={data}
          success_type={pay_type}
          source_type={selectedCards.primary.payment_source_type}
        />
      )}
      {paySuccesfull && <CopyRightFooter />}

      {/* {paymentData && paymentData.is_error == 0 && <RecurringPaymentSuccessfull/>} */}
      {/* <RecurringPaymentSuccessfull/> */}
    </>
  );
};
export default PayRecurring;
