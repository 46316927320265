import "./CopyRightFooter.scss";

const CopyRightFooter = () => {
  return (
    <footer className="copyright-footer">
      <p className="copyright-text">
        Copyright © 2025 Recuvery. All Rights Reserved.
      </p>
    </footer>
  );
};

export default CopyRightFooter;
